@color-violet-background: #2D1F59;
@color-red-btn-background: #EA2131;

@color-ultra-violet: #6648B6;
@color-violet-text: @color-ultra-violet;
@color-line-grey: rgba(161,161,161, 0.5);
@color-text-grey:#a1a1a1;
@color-text-red: #ED1C29;
@color-text-dark-violet: #2F2362;
@color-text-violet: @color-ultra-violet;
@color-background-green: #009347;
@color-background-light-grey: @color-text-grey;
@color-text-green: @color-background-green;
@color-text-dark: #2f394c;
@color-background-dark-text: #2F394C;
@color-background-grey: #E5E5E5; /* #f4f4f7;*/

/*vote colours*/
@color-vote-yes:@color-background-green;
@color-vote-no: @color-text-red;
@color-vote-dontcare: @color-background-dark-text;

/*desktop breakpoint*/
@width-desktop-breakpoint: 800px;

/* высота фиолетовой части на desktop */
@height-violet: 280px;

/*высота шрифта для заголовка/подзаголовка*/
@font-size-header:22px;


/* Отступы между items в списках 12px вместо 22px */
@form-item-padding: 12px 0px;
@form-field-padding: 12px 0px;

.font-header() {
  .font-normal();
  font-weight: bold;
  font-size: @font-size-header;
  line-height: 26px;
}

.normal-message {
  .font-normal();
}

.font-edit(){
    .font-normal();
    font-size:18px;
}

.header(){
    .font-header();
    margin: 20px 0px;
}

.font-normal(){
    color:@color-text-dark;
    font-size:16px;
    line-height:22px;
    font-family: 'Helvetica';
    font-weight: 400;
}

.font-light-text(){
    color:@color-text-grey;
    font-size:12px;
    line-height:14px;
}

.font-subheader(){
    .font-normal();
    font-size:20px;
    line-height:26px;
}

.font-bold{
    .font-normal();
    font-weight:700;
}

.light-text() {
  .font-normal();
  color: @color-text-grey;
  line-height: 22px;
}


.topheader-text(@hdr-color: @color-text-dark) {
  .topheader-text {
    .font-bold();
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    margin-top: 9px;
    line-height: 22px;
    color: @hdr-color;

    .icon {
      fill: @hdr-color;
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }
  }
}


  .hr {
    height: 1px;
    box-sizing: border-box;
    margin: 20px 0px 20px 0px;
    border-top: solid 1px @color-line-grey;
  }

.dialog-btn-row() {
  display: flex;
  justify-content: space-evenly;
  /*
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;

  -webkit-justify-content: space-evenly;
   */
}

  .dialog-button {
    width: 134px;
    height: 44px;
    background-color: white;
    border: solid 1px @color-line-grey !important;
    .font-bold();

    &.cancel {
      color: @color-violet-text;
    }

    &.cancel-red {
      color: @color-text-dark-violet;
    }

    &.action {
      color: @color-text-red;
    }

    &.action-green {
      color: @color-text-green;
    }

    &.action-violet {
      color: @color-text-violet;
    }

    &.action-red {
      color: @color-text-red;
    }

    &.delete-red {
      color: @color-text-red;
      width: 134px * 2 + 2px;
    }

    &.register-violet {
      color: @color-text-violet;
      width: 134px * 2 + 2px;
    }

    &.cancel-register-red {
      color: @color-text-red;
      width: 134px * 2 + 2px;
    }

    &.location-violet {
      color: @color-text-violet;
      width: 100%;
    }

    position: relative;
  }

  .dialog-button.disabled::before {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.1;
  }

  .mat-dialog-content {
    .font-normal();
    text-align: center;
  }

  .mat-dialog-title {
    .font-bold();
    text-align: center;
  }

  .break-anywhere() {
    overflow-wrap: break-word;
    word-wrap: hyphenate;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: none;
    -moz-hyphens: none;
    -webkit-hyphens: none;
    hyphens: none;
  }

  .nav-item() {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    cursor: pointer;

    .cbx {
      margin-right: 8px;
    }

    .text-block {
      .first-line {
        .font-bold();
        max-height: 2.8em;
        overflow: hidden;
        position: relative;
      }

      .second-line {
        .font-normal();
        .break-anywhere();
      }

      .publish-status {
        .font-normal();
        color: @color-text-green;
      }

      .publish-status.grey {
        color: @color-text-grey;
      }
    }
  }

/*DEV-32318 */
@media (min-width: 0px) and (orientation: portrait) {

  .ngx-mat-timepicker-dialog {
    max-width: 90vw !important;
  }
}
