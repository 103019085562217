@import (less) 'vars.less';
/*@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';*/


html, body {
  height: 100%;
}

body {
  margin: 0;
  overflow: hidden;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.top-level-screen {
  background-image: url('/assets/backdrop.jpg');
  background-clip: border-box;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-box-orient: vertical;

  .screen-body {
    flex: 1;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-box-orient: vertical;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;

    .tch-icon {
      text-align: center;
      flex: 0.5;
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      flex-direction: column;
      -webkit-box-orient: vertical;
      justify-content: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
    }

    .padding-space {
      flex: 0.5;
    }
  }
}



.spinner-style {
  position: absolute;
  @spinner-size: 60px;
  top: @spinner-size;
  left: ~"calc( (100vw - @{spinner-size}) / 2)";
  top: ~"calc( (100vh - @{spinner-size}) / 2)";
  z-index: 65535;
}

input[matInput]{
    font-size:18px;
}
label.mat-form-field-label{font-size:15px;}


.snackbar-error {
  color: white;
  background-color: @color-text-red;
  .mat-button-wrapper{color:white;}
}
.snackbar-info {
  color: white;
  background-color: @color-violet-background;

  .mat-button-wrapper {
    color: white;
  }
  .mat-button{margin-left:-22px;}

  .mat-simple-snackbar{display:block; }
}


.snackbar-confirm {
  color: white;
  background-color: @color-violet-background;

  .mat-button-wrapper {
    color: white;
  }

}




.mat-checkbox-background {
  /*background-color: white !important;*/
}
.mat-checkbox-checked {
  .mat-checkbox-background{
    background-color: @color-ultra-violet !important;
  }
}
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: @color-ultra-violet !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: @color-ultra-violet !important;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: @color-ultra-violet !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: @color-ultra-violet !important;
}

.mat-checkbox-label {
  white-space: normal;
}



.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: @color-ultra-violet !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: @color-ultra-violet !important;
}


.mat-radio-label {
  white-space: normal;
}

.mat-dialog-container {
  overflow: hidden !important;
}




.mat-dialog-actions {
  margin: 0px -20px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-evenly;
  -webkit-justify-content: space-evenly;
  padding: 0px;
}

.mat-badge-content {background-color: @color-red-btn-background;}


  h3 {
    .font-normal();
    margin: 12px 0px;
    font-size: 20px;
  }

  /* диапазон дат*/
  .date-range {
    mat-form-field.date-field {
      .mat-form-field-infix {
        width: auto;
        min-width: 100px;
      }
    }
  }

  @media(min-width: 0px) {
    .date-range {
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      justify-content: space-between;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
    }
  }

  @media(min-width: @width-desktop-breakpoint) {
    .date-range {
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      justify-content: space-between;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
    }
  }

  .date-range .mat-error {
    display: block;
    width: 100%;
  }

  .hint-message {
    .font-normal();
    color: @color-text-grey;
  }

.green-message {
  .font-normal();
  color: @color-text-green;
}



  .topic-tmpl .text-block p, .result-tmpl p {
    margin-top: 0px;
  }

  .ql-editor {
    blockquote {
      border-left: 4px solid #ccc;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 16px;
    }

    img {
      max-width: 100%;
    }


    .ql-tooltip.ql-editing {
      left: 0px !important;
    }
  }

  .result-ql-editor {
    pre.ql-syntax {
      background-color: #23241f;
      color: #f8f8f2;
      overflow: visible;
    }

    img {
      max-width: 100%;
    }

    blockquote {
      border-left: 4px solid #ccc;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 16px;
    }
  }

/* предупреждение */
.warn-block {
  padding: 6px 12px;
  border-radius: 4px;

  &.ok {
    color: #000;
    background-color: rgba(102, 72, 182, 0.05);
    border: solid 1px #6648B6;
  }

  &.ok.violet {
    color: @color-text-violet !important;
  }

  &.warn {
    color: @color-text-red;
    background-color: rgba(237, 28, 41, 0.05);
    border: solid 1px @color-text-red;
  }
}

.grey-notice{.font-light-text();}
